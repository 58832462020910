import React from "react"

export default function Home() {
  return (
    <div style={{ color: `purple` }}>
      <h1>Hello Gatsby!</h1>
      <p>What a world.</p>
      <img src="https://github.com/JohnathanSorrentino/STA1002/blob/main/ProfilePic.jpeg?raw=true" alt="bearded man with monocular" />
    </div>
  );
}